.ml-15 {
  margin-left: 15rem !important;
}
.font-data {
  font: normal 12 px var(--katexfont) !important;
}

.pr-1 {
  padding-right: 0px !important;
}

.fa-md {
  font-size: 0.8em;
  line-height: 0.75em;
  vertical-align: -15%;
}
