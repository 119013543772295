* {
  margin: 0px 0px;
  padding: 0px;
  outline: none;
  box-sizing: border-box;
  /* list-style: none; */
  /* background-color: #e5e5e5; */
}

:root {
  --katexfont: "Saria";
}

ul {
  margin-bottom: 0px !important;
}

.card-shadow {
  box-shadow: 24px 24px 60px 0px rgb(241 241 241 / 9);
}

.card-around-shadow {
  box-shadow: 1px -13px 20px 0px rgb(241 241 241);
}

.required{
  color: red;
}
/* .Toastify__toast-container {
  width: 320px;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #f48b08 !important;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
} */

/* toastStyle={{ backgroundColor: '#00b050' }}  */
.swal-button--confirm {
  background-color: #00b050;
}

.swal-button--confirm:hover {
  background-color: #00b050 !important;
}

.katex {
  font: normal 18px var(--katexfont) !important;
}

.mathit {
  font-family: var(--katexfont) !important;
  font-style: normal !important;
}

.table-p-0> :not(caption)>*>* {
  padding: 0.2rem !important;
}

.table-p-0 {
  margin-bottom: 0px !important;
}

.katex .vlist {
  display: table-cell;
  vertical-align: bottom;
  position: relative;
  /* font-size: 18px; */
}

.katex .base,
.katex .strut {
  display: inline-block;
  /* font-size: 12px !important; */
  /* font: normal 14px KaTeX_Main, Times New Roman, serif !important; */
  /* font-weight: 900; */
}

.katex .mfrac>span>span {
  text-align: center;
  font-size: 13px;
}

select.lang-select.form-control-sm.form-control {
  border: none;
  font-weight: 600;
  font-size: 17px;
  width: 110px;
  margin: 35px 16px;
}

input:focus {
  outline: none;
}

select,
input,
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

select.form-control {
  -webkit-appearance: menulist;
}

.select-items div:hover,
.same-as-selected {
  background-color: #00b050;
}

.ladda-button.orange {
  background: #00b050;
  color: #fff;
  border-radius: 7px;
  border: 1px solid transparent;
  margin-bottom: 45px;
}

.ladda-button.orange:hover {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #00b050;
  color: #fff;
}

.ladda-button.orange[data-loading] {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #00b050;
  box-shadow: "0 0 0 0.25rem   #00b050";
}

.ladda-button .spinner {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  margin-top: -16px;
  opacity: 0;
}
.display-msg{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .katex .fontsize-ensurer.reset-size6.size3, .katex .sizing.reset-size6.size3 {
  font-size: 18px;
} */

.list-footer{
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #000;
  padding: 5px 10px;
  cursor: pointer;
  align-items: center;
}
.list-footer2{
  display: flex;
  justify-content: space-between;
  /* border-right: 1px solid #000; */
  padding: 5px 10px;
  cursor: pointer;
  align-items: center;
}
/* li:last-of-type {
  border-right: none;
} */